<template>
  <div>
    <Row class="m-b-5">

      <i-col :xs="12" :sm="12" :md="8" :lg="8">
        <Label class="title">列车编号</Label>
        <span>{{resourceModel.code}}</span>
      </i-col>
      <i-col :xs="12" :sm="12" :md="8" :lg="8">
        <Label class="title">列车类型</Label>
        <span>{{resourceModel.stationName}}</span>
      </i-col>
      <i-col :xs="12" :sm="12" :md="8" :lg="8">
        <Label class="title">是否可用</Label>
        <span v-if="resourceModel.enabled">可用</span>
        <span v-else>不可用</span>
      </i-col>
    </Row>
    <Row class="m-b-5">

      <i-col :xs="12" :sm="12" :md="8" :lg="8">
        <Label class="title">所属资产</Label>
        <span>{{resourceModel.assetName}}</span>
      </i-col>
    </Row>
  </div>
</template>

<script>
export default {
  props: {
    resourceModel: {
      type: Object,
      required: true
    }
  }
}
</script>
